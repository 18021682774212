import React, { Fragment } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import "../style/landing.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "wh-whitelogo.png" }) {
        id
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      background: file(relativePath: { eq: "beach.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <SEO title="We Hotel - The most attractively affordable price and comfortable stay in Langkawi and Penang" />
      <div className="bg-wrapper">
        <div style={{display:'none'}}><h1>We Hotel - Top Budget Hotel in Langkawi and Penang</h1>
          <p>We Hotel offer cheap price and premium experience hotel in the central of top places in Langkawi and penang. </p>
          </div>
        <div className="bg-layer">
          <Img
            fluid={data.background.childImageSharp.fluid}
            alt="langkawi beach background"
          />
        </div>

        <div className="landing background-tint">
          <div className="navigation-box">
            <Img fixed={data.image.childImageSharp.fixed} alt="we hotel logo" />
            <div className="button-row">
              <Link to="/langkawi" state={{ location: "langkawi" }}>
                <div className="button">Langkawi</div>
              </Link>
              <Link to="/penang" state={{ location: "penang" }}>
                <div className="button">Penang</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default IndexPage
